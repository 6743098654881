//   The base item includes all the attributes that are
// common among all library item types.
//
//   Each new library item type will be defined with

import { uniqueId } from 'hooks/useUniqueId';
import { User } from './user';
import { DataBlock, defaultBlocks } from './publisher/block';
import { TemplateType } from './template';
import { defaultSettings } from './publisher/settings';
import { defaultCallToAction } from './publisher/call-to-action';
import { BASE_STYLING } from './publisher/style';
import { COMPANY_NAME } from '../utility/constants';
import { defaultFallbackFont, defaultFontData, FontData } from './font';

// a type and an asset, then included in the exported Item type.
type BaseItem = {
  id: string;
  identifier: string;
  title: string;
  description: string;
  categories: Category[];
  tags: string[];
  is_enabled_for_program?: boolean;
  thumbnail_images: Array<{
    url: string;
    width?: number;
    height?: number;
  }>;
};

export type Image = BaseItem & {
  type: 'content_image';
  status?: string;
  source?: string;
  asset: { url: string };
  scope?: string;
};

export type Blocks = BaseItem & {
  type: 'content_blocks';
  asset: { blocks: DataBlock[]; preview_blocks: DataBlock[] };
};

export type Template = BaseItem & {
  type: 'template' | 'journey_template';
  source: 'kai';
  postId?: number;
  created_at?: string;
  status?: string;
  scope: 'global' | 'program';
  asset: { template: TemplateType };
};

export type Content = BaseItem & {
  type: 'content_post';
  asset: {
    post_url: string;
    image_url?: string;
    read_time_in_seconds?: number;
  };
};

export type FontStylesheet = BaseItem & {
  type: 'font_stylesheet';
  scope: 'global' | 'program';
  source: 'kai';
  status?: string;
  asset: {
    fonts: Array<FontData>;
    css?: {
      assetKey: string;
      url: string;
    };
    value: string;
    createdBy?: User;
    fallbackFont: string;
    fontFamily?: FontData['family'];
  };
};

export type CustomBlock = Blocks & {
  source: 'kai';
  scope: 'program';
  status?: string;
};

export type UpsertableItem = Image | Template | FontStylesheet;

export type Item = Image | Content | Blocks | Template | FontStylesheet;

export type Category = {
  id: number;
  program_id?: number;
  title: string;
  description: string;
  status?: string;
  identifier?: string;
  library_type?: 'content_image';
};

// The filter is used in the UI and passed to the hook for refining
// the displayed results and returned items
export type Filter =
  | {
      type: 'all';
    }
  | {
      type: 'selected';
    }
  | {
      type: 'category';
      id: number;
      status?: Array<string>;
    }
  | {
      type: 'search';
      search: string;
      status?: Array<string>;
      scope?: string;
    }
  | {
      type: 'ids';
      ids: string[];
    };

//   The generic names above are easy to type and read, but may clash
// with types from other collections that have a similar set of names.
//
//   For convenience, this also exports the types with aliases that
// are very unlikely to clash with any other types in the system.

export type LibraryBlocksLibrary = { [identifier: string]: Blocks };
export type {
  Category as LibraryCategory,
  Content as LibraryContent,
  Blocks as LibraryBlocks,
  Image as LibraryImage,
  Item as LibraryItem,
  Filter as LibraryFilter,
  Template as LibraryTemplate,
};

export enum PublisherType {
  campaigns,
  topicPages,
  journeys,
}

// This is a map of publisher types to the content block library categories
// that should be omitted. Some publisher use cases may not have support for
// certain types of content blocks, so we can omit them from appearing in the
// library here.
export const omittedLibraryCategories: Record<
  PublisherType,
  Category['identifier'][]
> = {
  [PublisherType.campaigns]: [],
  [PublisherType.topicPages]: ['attachments', 'polls', 'code'],
  [PublisherType.journeys]: [],
};

const defaultBaseItem: BaseItem = {
  id: 'new',
  identifier: '',
  title: '',
  description: '',
  categories: [],
  tags: [],
  thumbnail_images: [],
};

export const defaultTemplate: Template = {
  ...defaultBaseItem,
  type: 'template',
  source: 'kai',
  scope: 'program',
  postId: 0,
  asset: {
    template: {
      settings: defaultSettings,
      blocks: defaultBlocks,
      callToAction: defaultCallToAction,
      styles: BASE_STYLING,
    },
  },
};

export const defaultFontStylesheet: FontStylesheet = {
  ...defaultBaseItem,
  identifier: uniqueId(),
  source: 'kai',
  type: 'font_stylesheet',
  scope: 'program',
  asset: {
    fonts: [{ ...defaultFontData, assetKey: uniqueId() }],
    value: '',
    fallbackFont: defaultFallbackFont,
  },
};

export const defaultCustomBlock: CustomBlock = {
  ...defaultBaseItem,
  source: 'kai',
  type: 'content_blocks',
  scope: 'program',
  asset: {
    blocks: [],
    preview_blocks: [],
  },
};

export function newTemplate(asset: TemplateType): Template {
  return {
    ...defaultTemplate,
    asset: {
      template: asset,
    },
  };
}

export function templateAuthor(template: Template): string {
  return template.asset.template?.createdBy
    ? `${template.asset.template.createdBy.firstName} ${template.asset.template.createdBy.lastName}`
    : COMPANY_NAME;
}

export function fontAuthor(font: FontStylesheet): string {
  return font.asset && font.asset.createdBy
    ? `${font.asset.createdBy.firstName} ${font.asset.createdBy.lastName}`
    : COMPANY_NAME;
}

export function isGlobalItem(item: UpsertableItem): boolean {
  return item.source !== undefined && ['donkey', 'pony'].includes(item.source);
}

// Not the same as filtering items. This removes items from even being
// filterable and preprocesses the list based on feature flags and such.
export function unlistItems<T extends Item>(
  items: T[],
  config: Partial<{
    removePolls: boolean;
    removeCustomHtml: boolean;
    removeBoxIntegration: boolean;
    removeAudienceBlockTargeting: boolean;
  }> = {}
): T[] {
  if (
    !config.removePolls &&
    !config.removeCustomHtml &&
    !config.removeBoxIntegration
  )
    return items;

  let result = items;

  if (config.removePolls) {
    result = result.filter(
      (item) =>
        !item.categories.filter(
          ({ title }) => title.toLocaleLowerCase().indexOf('poll') >= 0
        ).length
    );
  }

  if (config.removeCustomHtml) {
    result = result.filter((item) => item.identifier !== 'custom_html');
  }

  if (config.removeBoxIntegration) {
    result = result.filter((item) => item.identifier !== 'box_integration');
  }

  if (config.removeAudienceBlockTargeting) {
    result = result.filter((item) => item.identifier !== 'dynamic_block');
  }

  return result;
}
