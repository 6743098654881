import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { MenuProps } from 'react-select/src/components/Menu';
import DateRangeInput from 'shared/DateRangeInput';
import { AbsoluteRange, DateRange } from 'shared/DateRangeInput/DateRange';
import { selectComponents, selectStyles, DisabledSelect } from '../Select';
import styles from './DateSelect.module.css';

const parseValue = (
  val?: string,
  forceIgnoreYear?: boolean
): AbsoluteRange | null => {
  if (!val) return null;

  const normalizedValue = val
    .replace(':ignore_year', '')
    .replace('[', '')
    .replace(']', '');
  const [fromDate, toDate] = normalizedValue
    .split('TO')
    .map((date) => date.trim());

  if (!fromDate || !toDate) return null;

  const format = fromDate.length > 5 ? 'yyyy-MM-dd' : 'MM-dd';
  const date1 = DateTime.fromFormat(fromDate, format);
  const date2 = DateTime.fromFormat(toDate, format);

  return new AbsoluteRange(
    date1,
    date2,
    true,
    forceIgnoreYear || val.includes(':ignore_year')
  );
};

function buildRangeStr(e: DateRange, dateFormat: string) {
  return `[${e
    .toAbsolute()
    .start.toFormat(dateFormat)} TO ${e
    .toAbsolute()
    .end.toFormat(dateFormat)}]`;
}

export const DateRangeSelect: React.FC<{
  onChange: (value?: string) => void;
  value?: string;
  forceIgnoreYear?: boolean;
  isDisabled?: boolean;
}> = ({ onChange, value, forceIgnoreYear, isDisabled }) => {
  const dateFormat = forceIgnoreYear ? 'MM-dd' : 'yyyy-MM-dd';
  const parsedValue = useMemo(() => parseValue(value, forceIgnoreYear), [
    forceIgnoreYear,
    value,
  ]);
  const dateRange = parsedValue ?? DateRange.buildFromKey('lastweek');
  const rangeStr = buildRangeStr(dateRange, dateFormat);

  /** Control the open/close state of the modal. */
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  /** Respond to DoneButton click */
  const onDone = (e: DateRange) => {
    const range = buildRangeStr(e, dateFormat);
    onChange(range);

    /** set to false to close Modal */
    setMenuIsOpen(() => false);
  };

  const Menu: React.FC<MenuProps<{ value: string; label: string }>> = (
    props
  ) => {
    return (
      <div>
        <div className={styles.offsetSpacerRange} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <components.Menu {...props} className={styles.rangeMenuWrapper}>
          <DateRangeInput
            onDone={onDone}
            initialValue={dateRange}
            allowFutureSelection
            shouldUseTimezone
            forceIgnoreYear={forceIgnoreYear}
          />
        </components.Menu>
      </div>
    );
  };

  if (isDisabled) {
    return <DisabledSelect placeholder={value || 'Value'} />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select
      value={{ label: rangeStr, value: rangeStr }}
      options={[]}
      components={{ ...selectComponents, Menu }}
      styles={selectStyles}
      menuIsOpen={menuIsOpen}
      onMenuClose={() => setMenuIsOpen(() => false)}
      onMenuOpen={() => setMenuIsOpen(() => true)}
      ref={() => {
        if (!parsedValue) onChange(rangeStr);
      }}
    />
  );
};
