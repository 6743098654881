import React from 'react';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { Activity } from 'models/activity';
import DOMPurify from 'dompurify';
import { DateTime } from 'luxon';
import { activityToThumbnail } from './mappings';
import styles from './activities.module.css';

type PropsType = {
  activity: Activity;
  index: number;
};

export const ActivityBanner: React.FC<PropsType> = ({ activity, index }) => {
  const message = DOMPurify.sanitize(activity.message, {
    USE_PROFILES: { html: true },
    ADD_ATTR: ['target'],
  });

  return (
    <EmptyBanner
      thumbnail={activityToThumbnail(activity)}
      menuItems={[]}
      rowId={index.toString()}
    >
      <div className={styles.BannerWrapper}>
        <div className={styles.Basic}>
          <div className={styles.Title}>
            {activity.user &&
              `${activity.user.firstName} ${activity.user.lastName}`}
            {!activity.user && 'System'}
          </div>
          <div className={styles.Description}>
            {activity.user?.primaryEmail}
          </div>
        </div>
        <div className={styles.InfoWrapper}>
          <div
            className={styles.Message}
            title={message} // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <div className={styles.Date}>
            {DateTime.fromJSDate(new Date(activity.date)).toFormat(
              'MM/dd/yyyy'
            )}
          </div>
        </div>
      </div>
    </EmptyBanner>
  );
};
