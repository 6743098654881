import React from 'react';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';
import { CaptionJobStatus } from './CaptionJobStatus';

export const CaptionJobs: React.FC = () => {
  const {
    transcriptionJob,
    translationJob,
    isCaptionJobsLoading,
    isLanguagesLoading,
    isCaptionTranscriptionEnabled,
    isCaptionTranslationEnabled,
    isLoadingFeatureFlags,
    dismissTranscription,
    dismissTranslation,
  } = useCaptionsJobs();

  const transcriptionJobsComplete = () => {
    if (!isCaptionTranscriptionEnabled) return true;
    if (!transcriptionJob) return true;

    if (transcriptionJob && transcriptionJob.status === 'completed')
      return true;

    return false;
  };

  const translationJobsComplete = () => {
    if (!isCaptionTranslationEnabled) return true;
    if (!translationJob) return true;

    if (translationJob && translationJob.status === 'completed') return true;

    return false;
  };

  const allJobsComplete = () => {
    if (isLoadingFeatureFlags) return true;

    return transcriptionJobsComplete() && translationJobsComplete();
  };

  if (allJobsComplete()) {
    return null;
  }

  return (
    <>
      {isCaptionJobsLoading && isLanguagesLoading && (
        <LoadingSpinner size="small" />
      )}
      {isCaptionTranscriptionEnabled && transcriptionJob && (
        <CaptionJobStatus
          captionJob={transcriptionJob}
          waitingLabel="Transcription waiting..."
          processingLabel="Transcribing..."
          failureLabel={transcriptionJob.message || 'Transcription failed.'}
          onDismiss={dismissTranscription}
        />
      )}

      {isCaptionTranslationEnabled &&
        translationJob &&
        ((!transcriptionJob && translationJob.status !== 'waiting') ||
          (!!transcriptionJob &&
            !['failed', 'dismissed'].includes(transcriptionJob?.status))) && (
          <CaptionJobStatus
            captionJob={translationJob}
            waitingLabel="Waiting..."
            processingLabel="Translating..."
            failureLabel="Translation failed."
            onDismiss={dismissTranslation}
          />
        )}
    </>
  );
};
