import { useDesign } from 'contexts/design';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useCaptionsJobsQuery, useCaptionsLanguagesQuery } from 'hooks/video';
import { VideoFieldData } from 'models/donkey';
import { Caption } from 'models/video';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import {
  dismissTranscriptionJob,
  dismissTranslationJob,
  JobDismissProps,
  TranscribeProps,
  transcribeVideo,
  TranscriptionJob,
  TranslateProps,
  translateVideo,
  TranslationJob,
} from 'services/api-captions';

type CaptionJobsContextProps = {
  isCaptionJobsLoading: boolean;
  isLanguagesLoading: boolean;
  setTranscriptionJob: (job?: TranscriptionJob) => void;
  setTranslationJob: (job?: TranslationJob) => void;
  transcriptionJob?: TranscriptionJob;
  translationJob?: TranslationJob;
  isLoadingFeatureFlags: boolean;
  isCaptionTranscriptionEnabled: boolean;
  isCaptionTranslationEnabled: boolean;
  availableLanguages: { [languageCode: string]: string };
  dismissTranscription: () => void;
  dismissTranslation: () => void;
};

const CaptionJobsContext = createContext<CaptionJobsContextProps>({
  isCaptionJobsLoading: false,
  isLanguagesLoading: false,
  setTranscriptionJob: () => {},
  setTranslationJob: () => {},
  transcriptionJob: undefined,
  translationJob: undefined,
  isCaptionTranscriptionEnabled: false,
  isCaptionTranslationEnabled: false,
  isLoadingFeatureFlags: false,
  availableLanguages: {},
  dismissTranscription: () => {},
  dismissTranslation: () => {},
});

export const CaptionJobsProvider: React.FC<{
  fieldData: VideoFieldData;
  videoCaptions?: Array<Caption>;
}> = ({ fieldData, videoCaptions, children }) => {
  const { id: programId } = useProgram();
  const [transcriptionJob, setTranscriptionJob] = useState<
    TranscriptionJob | undefined
  >();

  const [translationJob, setTranslationJob] = useState<
    TranslationJob | undefined
  >();

  const {
    data: captionTranscriptionFeatureFlag,
    isLoading: isLoadingCaptionTranscriptionFeatureFlag,
  } = useFeatureFlagsQuery(programId, 'Studio.Publish.CaptionTranscription');

  const {
    data: captionTranslationFeatureFlag,
    isLoading: isLoadingCaptionTranslationFeatureFlag,
  } = useFeatureFlagsQuery(programId, 'Studio.Publish.CaptionTranslation');

  const isLoadingFeatureFlags =
    isLoadingCaptionTranscriptionFeatureFlag ||
    isLoadingCaptionTranslationFeatureFlag;

  const isCaptionTranscriptionEnabled = !!captionTranscriptionFeatureFlag?.value;
  const isCaptionTranslationEnabled = !!captionTranslationFeatureFlag?.value;

  const { active: isDesignAsset } = useDesign();

  const areJobsIncomplete = () => {
    if (translationJob?.status === 'processing') return true;

    if (
      transcriptionJob &&
      ['failed', 'dismissed'].includes(transcriptionJob?.status)
    )
      return false;

    if (
      transcriptionJob?.status === 'completed' &&
      (!translationJob ||
        ['waiting', 'processing'].includes(translationJob.status))
    )
      return true;

    if (transcriptionJob?.status === 'processing') return true;

    return false;
  };

  const {
    data: captionJobs,
    isLoading: isCaptionJobsLoading,
  } = useCaptionsJobsQuery({
    videoId: fieldData.video_id,
    programId,
    isDesignAsset,
    enabled: isCaptionTranscriptionEnabled,
    refetchInterval: areJobsIncomplete() ? 3000 : undefined,
  });

  const {
    data: awsTranslationLanguages,
    isLoading: isLanguagesLoading,
  } = useCaptionsLanguagesQuery({
    programId,
    enabled: isCaptionTranslationEnabled,
  });

  const availableLanguages = useMemo(() => {
    const existingCaptionLanguages =
      videoCaptions?.map((caption) => caption.locale) ?? [];
    return awsTranslationLanguages
      ? Object.fromEntries(
          awsTranslationLanguages
            .filter(
              (language) =>
                !existingCaptionLanguages.includes(language.languageCode)
            )
            .map((language) => [language.languageCode, language.languageName])
        )
      : {};
  }, [awsTranslationLanguages, videoCaptions]);

  useEffect(() => {
    if (captionJobs?.transcription) {
      setTranscriptionJob(captionJobs.transcription);
    }
  }, [captionJobs?.transcription]);

  useEffect(() => {
    if (captionJobs?.translation) {
      setTranslationJob(captionJobs.translation);
    }
  }, [captionJobs?.translation]);

  const {
    dismissTranscription: dismissTranscriptionJobHook,
  } = useDismissTranscribeJob({
    onSuccess: () => {},
  });

  const {
    dismissTranslation: dismissTranslationJobHook,
  } = useDismissTranslateJob({
    onSuccess: () => {},
  });

  const queryClient = useQueryClient();

  const dismissTranscription = React.useCallback(() => {
    if (!fieldData.video_id || !transcriptionJob) return;

    dismissTranscriptionJobHook(
      {
        programId,
        jobId: transcriptionJob.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'captions-jobs',
            programId,
            fieldData.video_id,
          ]);
        },
      }
    );
  }, [
    dismissTranscriptionJobHook,
    fieldData.video_id,
    programId,
    queryClient,
    transcriptionJob,
  ]);

  const dismissTranslation = React.useCallback(() => {
    if (!fieldData.video_id || !translationJob) return;

    dismissTranslationJobHook(
      {
        programId,
        jobId: translationJob.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'captions-jobs',
            programId,
            fieldData.video_id,
          ]);
        },
      }
    );
  }, [
    dismissTranslationJobHook,
    fieldData.video_id,
    programId,
    queryClient,
    translationJob,
  ]);

  return (
    <CaptionJobsContext.Provider
      value={{
        transcriptionJob,
        translationJob,
        isCaptionJobsLoading,
        isLanguagesLoading,
        setTranscriptionJob,
        setTranslationJob,
        isLoadingFeatureFlags,
        isCaptionTranscriptionEnabled,
        isCaptionTranslationEnabled,
        availableLanguages,
        dismissTranscription,
        dismissTranslation,
      }}
    >
      {children}
    </CaptionJobsContext.Provider>
  );
};

export const useCaptionsJobs: () => CaptionJobsContextProps = () => {
  const context = useContext(CaptionJobsContext);
  if (context === undefined) {
    throw new Error(
      'useCaptionsJobs must be used within a CaptionJobsProvider'
    );
  }
  return context;
};

// transcribe video hooks

type UseTranscribeVideoProps = {
  onSuccess: (data: TranscriptionJob) => void;
};

type UseTranscribeVideoReturn = {
  startTranscription: UseMutateFunction<
    TranscriptionJob,
    Error,
    TranscribeProps
  >;
  isTranscribing: boolean;
};

export const useTranscribeVideo = ({
  onSuccess,
}: UseTranscribeVideoProps): UseTranscribeVideoReturn => {
  const { mutate: startTranscription, isLoading: isTranscribing } = useMutation<
    TranscriptionJob,
    Error,
    TranscribeProps
  >(transcribeVideo, {
    onSuccess,
  });
  return { startTranscription, isTranscribing };
};

// dismiss transcribe video hooks

type UseDismissTranscribeJobProps = UseTranscribeVideoProps;

type UseDismissTranscribeJobReturn = {
  dismissTranscription: UseMutateFunction<
    TranscriptionJob,
    Error,
    JobDismissProps
  >;
  isDismissing: boolean;
};

export const useDismissTranscribeJob = ({
  onSuccess,
}: UseDismissTranscribeJobProps): UseDismissTranscribeJobReturn => {
  const { mutate: dismissTranscription, isLoading: isDismissing } = useMutation<
    TranscriptionJob,
    Error,
    JobDismissProps
  >(dismissTranscriptionJob, {
    onSuccess,
  });
  return { dismissTranscription, isDismissing };
};

// translate video hooks

type UseTranslateVideoProps = {
  languages: string[];
  onSuccess: (data: TranslationJob) => void;
};

type UseTranslateVideoReturn = {
  startTranslation: UseMutateFunction<TranslationJob, Error, TranslateProps>;
  isTranslating: boolean;
};

export const useTranslateVideo = ({
  onSuccess,
}: UseTranslateVideoProps): UseTranslateVideoReturn => {
  const { mutate: startTranslation, isLoading: isTranslating } = useMutation<
    TranslationJob,
    Error,
    TranslateProps
  >(translateVideo, {
    onSuccess,
  });
  return { startTranslation, isTranslating };
};

// dismiss translate video hooks

type UseDismissTranslateJobProps = {
  onSuccess: (data: TranslationJob) => void;
};

type UseDismissTranslateJobReturn = {
  dismissTranslation: UseMutateFunction<TranslationJob, Error, JobDismissProps>;
  isDismissing: boolean;
};

export const useDismissTranslateJob = ({
  onSuccess,
}: UseDismissTranslateJobProps): UseDismissTranslateJobReturn => {
  const { mutate: dismissTranslation, isLoading: isDismissing } = useMutation<
    TranslationJob,
    Error,
    JobDismissProps
  >(dismissTranslationJob, {
    onSuccess,
  });
  return { dismissTranslation, isDismissing };
};
