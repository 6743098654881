import * as React from 'react';
import cx from 'classnames';
import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import { usePermissions } from 'contexts/permissions';
import { useUser } from 'contexts/user';
import { HoverIconMenu } from 'shared/hover-dropdown/HoverIconMenu';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import defaultAvatar from 'assets/images/User.png';
import { Bell } from 'shared/icons';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import {
  useDraftsCountQuery,
  useFeedPostsCountQuery,
  useFlaggedCountQuery,
  useSubmittedCountQuery,
} from 'hooks/posts-metadata';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Link } from '@reach/router';
import { Flex } from './Flex';
import styles from './layout.module.css';
import { Body } from '../Typography';

const useNotifications = () => {
  const { id: adminId } = useUser();
  const { id: programId } = useProgram();
  const flagPostEnabled = !!useFeatureFlagsQuery(
    programId,
    'EE.FlagPost.Enabled'
  ).data?.value;
  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;
  const {
    permissions: {
      moderateCommentAccess,
      moderateContentAccess,
      publishCampaign,
    },
  } = usePermissions();
  const drafts = useDraftsCountQuery(programId, adminId);
  const feed = useFeedPostsCountQuery(programId);
  const submitted = useSubmittedCountQuery(programId);
  const flagged = useFlaggedCountQuery(programId);
  // we don't need to wait for the counters that the user doesn't have access to
  const flaggedCount =
    (flagged.data?.flaggedCommentsCount ?? 0) +
    (flagged.data?.flaggedContentCount ?? 0);

  const moderateCommentOrContentAccess =
    moderateCommentAccess || moderateContentAccess;

  const iconCount = {
    ready:
      (moderateCommentOrContentAccess ? flagged.data : true) && submitted.data,
    value:
      (moderateCommentOrContentAccess ? flaggedCount : 0) +
      (submitted.data?.submittedCount ?? 0),
  };
  const total = {
    ready:
      (moderateCommentOrContentAccess ? flagged.data : true) &&
      submitted.data &&
      drafts.data &&
      (publishCampaign ? feed.data : true),
    value:
      (moderateCommentOrContentAccess ? flaggedCount : 0) +
      (drafts.data?.ownDraftsCount ?? 0) +
      (publishCampaign ? feed.data?.feedPostsCount ?? 0 : 0) +
      (submitted.data?.submittedCount ?? 0),
  };
  const url = React.useCallback(
    (path: string) => `/${programId}/app/start-content/${path}`,
    [programId]
  );
  const flaggedEntitiesToFilterBy = flagPostEnabled
    ? ['comments', 'campaigns']
    : ['comments'];
  const urlEncodedComma = '%2C';

  const urls = {
    submitted: url('review?sourceTypes=submitted%2Csubmitted&sources=true'),
    flagged: url(
      `sent?flaggedEntities=${flaggedEntitiesToFilterBy.join(urlEncodedComma)}`
    ),
    drafts: url(
      `draft?${useAuthorAliases ? 'creators' : 'publishers'}=${adminId}`
    ),
    feed: url('review?sourceTypes=external%2Cexternal&sources=true'),
  };
  return { drafts, feed, submitted, flagged, iconCount, total, urls };
};

const NotifLink: React.FC<{
  to: string;
  label: string;
  ready: boolean;
  count?: number;
  bordered?: boolean;
  pendoClass?: string;
}> = ({ bordered, to, label, ready, count, pendoClass }) =>
  ready && count && count > 0 ? (
    <Link
      to={to}
      className={cx(
        styles.NotificationsItem,
        {
          [styles.WithBorder]: bordered,
        },
        pendoClass
      )}
    >
      <div>{label}</div>
      <div>{!ready ? <LoadingSpinner size="small" /> : count ?? 0}</div>
    </Link>
  ) : (
    <></>
  );

const Notifications: React.FC = () => {
  const notifs = useNotifications();
  const {
    permissions: {
      moderateCommentAccess,
      moderateContentAccess,
      publishCampaign,
    },
  } = usePermissions();

  const showMenu = React.useMemo(() => {
    if (notifs.total.ready) {
      return notifs.total.value > 0;
    }
    return true;
  }, [notifs.total.ready, notifs.total.value]);

  return (
    <HoverDropdown
      openDelay="click"
      dropdownRenderProp={() =>
        showMenu ? (
          <div className={styles.Notifications}>
            {publishCampaign && (
              <NotifLink
                label="Submitted"
                to={notifs.urls.submitted}
                ready={!notifs.submitted.isLoading}
                count={notifs.submitted.data?.submittedCount}
              />
            )}
            {(moderateCommentAccess || moderateContentAccess) && (
              <NotifLink
                pendoClass="pendo-flagged-notification"
                label="Flagged"
                to={notifs.urls.flagged}
                ready={!notifs.flagged.isLoading}
                count={
                  (notifs.flagged.data?.flaggedCommentsCount ?? 0) +
                  (notifs.flagged.data?.flaggedContentCount ?? 0)
                }
                bordered
              />
            )}
            <NotifLink
              label="Your Drafts"
              to={notifs.urls.drafts}
              ready={!notifs.drafts.isLoading}
              count={notifs.drafts.data?.ownDraftsCount}
            />
            {publishCampaign && (
              <NotifLink
                label="Feed Posts"
                to={notifs.urls.feed}
                ready={!notifs.feed.isLoading}
                count={notifs.feed.data?.feedPostsCount}
              />
            )}
          </div>
        ) : (
          <></>
        )
      }
    >
      <div className={styles.NotificationsBellContainer}>
        {!notifs.iconCount.ready ? (
          <LoadingSpinner size="small" />
        ) : (
          <div className={styles.NotificationsCounter}>
            {notifs.iconCount.value}
          </div>
        )}
        <Bell />
      </div>
    </HoverDropdown>
  );
};

const Profile: React.FC = () => {
  const { avatarUrl, preferredName, lastName, firstName } = useUser();
  const name = `${firstName} ${lastName}`;
  const menuItems = [
    {
      title: 'Sign Out',
      href: '/auth/logout',
      danger: true,
    },
  ];
  return (
    <HoverIconMenu
      menuItems={menuItems}
      dropdownClassName="dropdown-align-right"
      openDelay="click"
      profileName={preferredName || name}
    >
      <Flex
        title="User's avatar"
        className={cx(styles.AvatarImage, {
          [styles.DefaultAvatarImage]: !avatarUrl,
        })}
        style={{
          backgroundImage: `url(${avatarUrl}), linear-gradient(var(--color-brandFull) 80%, var(--color-brandShadeLight))`,
        }}
      >
        {avatarUrl ? null : <img src={defaultAvatar} alt="Default Avatar" />}
      </Flex>
    </HoverIconMenu>
  );
};

const FIRSTUP_SUPPORT_URL = 'https://support.firstup.io';
const TRAINING_CENTER_URL = 'https://auth.socialchorus.com/saml/learnupon/new';
const IDEAS_PORTAL_URL = 'https://firstupideasportal.ideas.aha.io';

const Support: React.FC = () => {
  const { id: programId, brandSlug } = useProgram();
  const { id: userId } = useUser();

  const { data: ideasPortalEnabled, isLoading } = useFeatureFlagsQuery(
    programId,
    'Studio.IdeasPortal',
    userId
  );

  if (isLoading) return null;

  if (!ideasPortalEnabled?.value)
    return (
      <a
        className={styles.Flex}
        href={FIRSTUP_SUPPORT_URL}
        rel="noreferrer"
        target="_blank"
      >
        <SVGIcon name="HelpOutline" />
      </a>
    );

  const trainingUrlParams = qs.stringify(
    snakeCaseKeys({ programId, disambiguationKey: brandSlug })
  );

  return (
    <HoverDropdown
      openDelay="click"
      dropdownClassName="dropdown-align-right"
      dropdownRenderProp={() => (
        <div className={styles.SupportDropdown}>
          <a
            className={styles.SupportDropdownItem}
            href={FIRSTUP_SUPPORT_URL}
            rel="noreferrer"
            target="_blank"
          >
            <Body>Get Help</Body>
          </a>
          <a
            className={styles.SupportDropdownItem}
            href={`${TRAINING_CENTER_URL}?${trainingUrlParams}`}
            rel="noreferrer"
            target="_blank"
          >
            <Body>Training Center</Body>
          </a>
          <a
            className={styles.SupportDropdownItem}
            href={IDEAS_PORTAL_URL}
            rel="noreferrer"
            target="_blank"
          >
            <Body>Suggest an Idea</Body>
          </a>
        </div>
      )}
    >
      <span className={styles.Flex}>
        <SVGIcon name="HelpOutline" />
        <SVGIcon name="ChevronDown" />
      </span>
    </HoverDropdown>
  );
};

export const SiteHeader: React.FC<{
  innerRef: React.MutableRefObject<HTMLElement | null>;
  showNotification: boolean;
  onAIAssistedNavigationClick: () => void;
}> = ({ innerRef, showNotification, onAIAssistedNavigationClick }) => {
  const { id: programId } = useProgram();
  const { data: showAIAssistedSearchIcon } = useFeatureFlagsQuery(
    programId,
    'Studio.AI.Navigation'
  );
  return (
    <header className={styles.SiteHeader} ref={innerRef}>
      <div className={styles.UserMenu}>
        {showAIAssistedSearchIcon?.value && (
          <button
            type="button"
            className={cx(styles.noStyleButton, styles.Flex)}
            onClick={onAIAssistedNavigationClick}
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.19704 11.3833H8.61579L8.40977 11.1912C9.13082 10.3802 9.56492 9.32729 9.56492 8.18189C9.56492 5.62787 7.42385 3.55762 4.78246 3.55762C2.14107 3.55762 0 5.62787 0 8.18189C0 10.7359 2.14107 12.8062 4.78246 12.8062C5.96704 12.8062 7.05597 12.3864 7.89474 11.6892L8.09339 11.8884V12.4505L11.7722 16.0005L12.8685 14.9405L9.19704 11.3833ZM4.78246 11.3833C2.95041 11.3833 1.47153 9.95335 1.47153 8.18189C1.47153 6.41044 2.95041 4.98047 4.78246 4.98047C6.61451 4.98047 8.09339 6.41044 8.09339 8.18189C8.09339 9.95335 6.61451 11.3833 4.78246 11.3833Z"
                fill="#1B1A1F"
              />
              <g clipPath="url(#clip0_1534_15430)">
                <path
                  d="M13.8607 1.6748L14.8606 2.0613L15.2606 3.02753C15.2939 3.07584 15.3439 3.10805 15.3939 3.10805C15.4272 3.10805 15.4772 3.07584 15.5106 3.02753L15.9272 2.0613L16.9104 1.6748C16.9604 1.6426 16.9937 1.59428 16.9937 1.54597C16.9937 1.51376 16.9604 1.46545 16.9104 1.43325L15.9272 1.03065L15.5106 0.0805194C15.4772 0.0322078 15.4272 0 15.3939 0C15.3439 0 15.2939 0.0322078 15.2606 0.0805194L14.8606 1.03065L13.8607 1.43325C13.8107 1.46545 13.7774 1.51376 13.7774 1.54597C13.7774 1.59428 13.8107 1.6426 13.8607 1.6748ZM16.9104 6.58649L15.9272 6.18389L15.5106 5.23376C15.4772 5.18545 15.4272 5.15324 15.3939 5.15324C15.3439 5.15324 15.2939 5.18545 15.2606 5.23376L14.8606 6.18389L13.8607 6.58649C13.8107 6.6187 13.7774 6.66701 13.7774 6.69921C13.7774 6.74753 13.8107 6.79584 13.8607 6.82805L14.8606 7.21454L15.2606 8.18077C15.2939 8.22908 15.3439 8.26129 15.3939 8.26129C15.4272 8.26129 15.4772 8.22908 15.5106 8.18077L15.9272 7.21454L16.9104 6.82805C16.9604 6.79584 16.9937 6.74753 16.9937 6.69921C16.9937 6.66701 16.9604 6.6187 16.9104 6.58649ZM14.8606 4.12259C14.844 4.04207 14.794 3.92935 14.7106 3.89714L12.8275 2.97922L11.8943 1.17558C11.7943 0.998441 11.511 0.998441 11.411 1.17558L10.4777 2.97922L8.5946 3.89714C8.51127 3.92935 8.44461 4.04207 8.44461 4.12259C8.44461 4.21922 8.51127 4.31584 8.5946 4.34805L10.4777 5.26597L11.411 7.08571C11.461 7.16623 11.561 7.21454 11.661 7.21454C11.7443 7.21454 11.8443 7.16623 11.8943 7.08571L12.8275 5.26597L14.7106 4.34805C14.794 4.31584 14.8606 4.21922 14.8606 4.12259Z"
                  fill="#1B1A1F"
                />
              </g>
              <defs>
                <clipPath id="clip0_1534_15430">
                  <rect
                    width="8.53872"
                    height="8.2563"
                    fill="white"
                    transform="translate(8.46127)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
        <Support />
        {showNotification && <Notifications />}
        <Profile />
      </div>
    </header>
  );
};
