import * as React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body, Heading, Subheading } from 'DesignSystem/Typography';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
} from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { useLinkSections } from './useLinkSections';
import { LinkFlags } from './constant';
import { useUser } from '../../../../contexts/user';
import { useMergeIntegrationEnabled } from '../MergeIntegration';
import styles from './landing-page.module.css';

export const LandingPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { id: currentUserId } = useUser();

  const { permissions } = usePermissions();

  const { data: programCustomizations } = useProgramCustomizationsQuery(
    programId
  );

  const { data: styleGuideEnabled } = useFeatureFlagsQuery(
    programId,
    'StyleGuide.Enabled'
  );

  const { data: showPermissionsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.UI'
  );

  const { data: showStandardRoles } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowStandardRoles'
  );

  const { data: showCustomRoles } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowCustomRoles'
  );

  const { data: setChannelDefaultsEnabled } = useFeatureFlagsQuery(
    programId,
    'SetChannelDefaults.Enabled'
  );

  const { data: kaiframesEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Parity.Kaiframes'
  );

  const emailOnly = !!useFeatureFlag('emailOnly');

  const { data: showLegacyConfigure } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.ShowLegacyConfigure'
  );

  const { data: showActivitiesUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Activities.UI'
  );

  const { data: bulkUpdatePermissionsEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.BulkUpdatePermissions.UI'
  );

  const { data: shortcutsEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Shortcuts.Enabled'
  );

  const { data: tagUsersEnabled } = useFeatureFlagsQuery(
    programId,
    'EE.TagUser.Enabled'
  );

  const { data: deliveryThresholdEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.DeliveryThresholdEnabled'
  );

  const { data: engagementBoostDefaultEnabled } = useFeatureFlagsQuery(
    programId,
    'Orchestrate.EngagementBoostDefault.Enabled'
  );

  const { data: tableauIntegrationEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration'
  );

  const { data: tableauIntegrationOverride } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration.Override',
    currentUserId
  );

  const { data: showCommunityDetailsUI } = useFeatureFlagsQuery(
    programId,
    'Studio.CommunityDetails.UI'
  );

  const { data: customURLsEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.CustomURLsEnabled'
  );

  const { data: authorAliasesEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.AuthorAliases'
  );

  const { data: displayRegionalDomains } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.ShowRegionalDomains'
  );

  const { data: displayProgramSearch } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.ShowProgramSearch'
  );

  const { data: personalizedFieldsFlag } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFieldsV2'
  );

  const { data: featureSettingsEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.FeatureSettings.Enabled'
  );

  const { data: boxIntegrationEnabled } = useFeatureFlagsQuery(
    programId,
    'License.Integration.BoxKnowledgeManagement'
  );

  const { data: captionTranscriptionEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CaptionTranscription'
  );

  const { enabled: mergeIntegrationEnabled } = useMergeIntegrationEnabled();

  const flags: LinkFlags = {
    styleGuideEnabled: !!styleGuideEnabled?.value,
    showPermissionsUI: !!showPermissionsUI?.value,
    showStandardRoles: !!showStandardRoles?.value,
    showCustomRoles: !!showCustomRoles?.value,
    setChannelDefaultsEnabled: !!setChannelDefaultsEnabled?.value && !emailOnly,
    showLegacyConfigure: !!showLegacyConfigure?.value,
    kaiframesEnabled: !!kaiframesEnabled?.value,
    microappsEnabled: !!programCustomizations?.microappsEnabled?.value,
    showActivitiesUI: !!showActivitiesUI?.value,
    bulkUpdatePermissionsEnabled: !!bulkUpdatePermissionsEnabled?.value,
    shortcutsEnabled: !!shortcutsEnabled?.value,
    tagUsersEnabled: !!tagUsersEnabled?.value,
    deliveryThresholdEnabled: !!deliveryThresholdEnabled?.value,
    tableauIntegrationEnabled: !!(
      tableauIntegrationEnabled?.value || tableauIntegrationOverride?.value
    ),
    showCommunityDetailsUI: !!showCommunityDetailsUI?.value,
    customURLsEnabled: !!customURLsEnabled?.value,
    engagementBoostDefaultEnabled: !!engagementBoostDefaultEnabled?.value,
    authorAliasesEnabled: !!authorAliasesEnabled?.value,
    displayRegionalDomains: !!displayRegionalDomains?.value,
    displayProgramSearch: !!displayProgramSearch?.value,
    personalizedFieldsFlagV2: !!personalizedFieldsFlag?.value,
    featureSettingsEnabled: !!featureSettingsEnabled?.value,
    boxIntegrationEnabled: !!boxIntegrationEnabled?.value,
    captionTranscriptionEnabled: !!captionTranscriptionEnabled?.value,
    mergeIntegrationEnabled,
  };

  const SECTIONS = useLinkSections(flags, permissions);
  return (
    <FormPage title="Configure">
      {SECTIONS.map(({ title, links }) => (
        <section className={styles.Section} key={title}>
          <Heading bold block>
            {title}
          </Heading>
          <Flex wrap start>
            {links.map((link) => (
              <Link key={link.to} to={link.to} className={styles.Link}>
                <Subheading bold block>
                  {link.title}
                </Subheading>
                <Body>{link.description}</Body>
              </Link>
            ))}
          </Flex>
        </section>
      ))}
    </FormPage>
  );
};
