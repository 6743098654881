import { DateTime } from 'luxon';
import React from 'react';
import Select, { components } from 'react-select';
import { MenuProps } from 'react-select/src/components/Menu';
import { DateTimeInput } from 'shared/DateTimeInput';
import { selectComponents, selectStyles, DisabledSelect } from '../Select';
import styles from './DateSelect.module.css';

const parseValue = (value?: string): DateTime | null => {
  if (!value) return null;

  const normalizedValue = value.replace(':ignore_year', '');
  const format = normalizedValue.length > 5 ? 'yyyy-MM-dd' : 'MM-dd';
  const parsedDate = DateTime.fromFormat(normalizedValue, format);
  if (parsedDate.isValid) return parsedDate;

  return null;
};

export const DateTimeSelect: React.FC<{
  onChange: (value?: string) => void;
  operator: string;
  value?: string;
  ignoreYearFlag: boolean;
  ignoreYear: boolean;
  toggleIgnoreYear: () => void;
  isDisabled?: boolean;
}> = ({
  onChange,
  value,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
  isDisabled,
}) => {
  const dateFormat = ignoreYear ? 'MM-dd' : 'yyyy-MM-dd';
  const parsedValue = parseValue(value);
  const selectedDate = parsedValue ?? DateTime.now();
  const formattedDate = selectedDate.toFormat(dateFormat);

  const handleDateChange = (e: DateTime) => {
    onChange(e.toFormat(dateFormat));
  };

  const Menu: React.FC<MenuProps<{ value: string; label: string }>> = (
    props
  ) => {
    return (
      <div>
        <div className={styles.offsetSpacer} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <components.Menu {...props} className={styles.dateTimeMenuWrapper}>
          <DateTimeInput
            value={selectedDate}
            hideTime
            onChange={handleDateChange}
            ignoreYearFlag={ignoreYearFlag}
            ignoreYear={ignoreYear}
            toggleIgnoreYear={toggleIgnoreYear}
          />
        </components.Menu>
      </div>
    );
  };

  if (isDisabled) {
    return <DisabledSelect placeholder={value || 'Value'} />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select
      value={{ label: formattedDate, value: formattedDate }}
      options={[]}
      components={{ ...selectComponents, Menu }}
      styles={{
        ...selectStyles,
        menu: (provided: React.CSSProperties) => ({
          ...provided,
          width: 'auto',
          backgroundColor: 'var(--color-gray00)',
          cursor: 'pointer',
        }),
      }}
      ref={() => {
        if (!parsedValue) onChange(formattedDate);
      }}
    />
  );
};
