import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import { useProgram } from 'contexts/program';
import { MergeIntegration } from 'models/merge-integration';
import { fetchIntegrations } from 'services/api-merge-integration';

export const getMergeIntegrationsQueryKey = (
  programId: number,
  type: string
): QueryKey => ['merge-integrations', programId, type];

export function useMergeIntegrations(
  type: string
): UseQueryResult<MergeIntegration[]> {
  const { id: programId } = useProgram();

  return useQuery(getMergeIntegrationsQueryKey(programId, type), () =>
    fetchIntegrations(programId, type)
  );
}
