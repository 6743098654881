import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from './feature-flags';

export function useJourneyMetricsV2Enabled(): {
  enabled: boolean;
  isLoading: boolean;
} {
  const programId = useProgram().id;
  const { data, isLoading } = useFeatureFlagsQuery(
    programId,
    'Studio.Intelligence.JourneyMetricsV2.Enabled'
  );

  return {
    enabled: Boolean(data?.value),
    isLoading,
  };
}
