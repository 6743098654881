import { DateTime } from 'luxon';
import { Comment } from 'services/api-content';
import styles from './comments-modal-v2.module.css';

type ParentCommentProps = {
  comment: Comment;
};

const formatDate = (d: string | undefined) => {
  return d ? DateTime.fromISO(d) : undefined;
};

export const ParentCommentView: React.FC<ParentCommentProps> = ({
  comment,
}) => {
  const dateCreated = formatDate(comment.createdAt)?.toFormat(
    'hh:mma MM/dd/yyyy'
  );

  return (
    <div className={styles.parentCommentView}>
      <div className={styles.responseHeader}>IN RESPONSE TO</div>
      <div className={styles.responseContent}>
        <div>
          {comment.author.name}&nbsp;on&nbsp;{dateCreated}
        </div>
        <div>Comment:&nbsp;{comment.rawContent}</div>
      </div>
    </div>
  );
};
