import { useCallback, useEffect, useState } from 'react';
import {
  QueryKey,
  QueryObserverResult,
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { Page, PaginationData } from 'services/common';
import { useFlashMessage } from '../contexts/flasher';
import { DEFAULT_ERROR_MESSAGE } from '../models/flash-message';

export type BulkSelection = BulkAllSelection | BulkNoneSelection;

type BulkAllSelection = {
  type: 'all';
  excludedIds: string[];
};

export type EmailAliasBulkActionFilters = {
  search?: string;
  status?: string[];
};

export type AuthorAliasBulkActionFilters = {
  search?: string;
  statuses?: string[];
  userIds?: string[];
  types?: string[];
};

export type TopicBulkActionFilters = {
  status?: string | string[];
  visibility?: string | string[];
  autoFollow?: string | string[];
  userSubmittable?: string | string[];
  autoPublish?: string | string[];
  topicTags?: string | string[];
  search?: string;
};

export type CustomSlugsActionFilters = {
  search?: string;
  // TODO: Add the rest of filters entities.
};

export type ExternalSourceActionFilters = {
  search?: string;
  statuses?: string[];
  shareable?: string[];
  autoPublish?: string[];
};

export type AudienceBulkActionFilters = {
  search?: string;
  tags?: string | string[];
  statuses?: string | string[];
  types?: string | string[];
  favorites?: boolean;
};

export type UserBulkActionFilters =
  | {
      search?: string;
      statuses?: string | string[];
      roles?: string | string[];
      audiences?: string[];
      scopes?: string[];
    }
  | undefined;

type BulkNoneSelection = {
  type: 'none';
  includedIds: string[];
};

export type InitiativeBulkActionFilters = {
  search?: string;
  statuses?: string[];
};

export type QueryError = { message: string };

export type QueryResponse<T> = {
  isLoading: boolean;
  errorMessage?: string;
  error?: Error | null;
  data?: T;
  isSuccess?: boolean;
  invalidateQuery?: () => void;
  isFetching?: boolean | undefined;
};

export type QueryResponseWithStatus<T> = QueryResponse<T> & {
  status: QueryObserverResult<T, unknown>['status'];
};

export type RefetchQueryResponse<T> = {
  isLoading: boolean;
  refetch: () => Promise<QueryObserverResult<T, QueryError>>;
};

export type PaginationState = {
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
};

export type InfiniteQueryResponse<T, S = PaginationData> = {
  isLoading: boolean;
  errorMessage?: string;
  data: Array<T>;
  meta?: S;
  invalidateQuery?: () => void;
} & PaginationState & {
    refetch?: (
      options?: Partial<{
        throwOnError: boolean;
        cancelRefetch: boolean;
      }>
    ) => Promise<UseQueryResult>;
  };

export function nextPageToFetch(
  paginationData: PaginationData,
  pageSize: number
): number | undefined {
  const { totalRecords, currentPage } = paginationData;
  if (totalRecords > currentPage * pageSize) {
    // sometime the backend return the currentPage as string
    return Number(currentPage) + 1;
  }
  return undefined;
}

export type EditResponse<T> = {
  data: T;
  isSaved: boolean;
  isSaving: boolean;
  revert: () => void;
  save: () => void;
  setData: (data: T) => void;
  errorMessage: string | undefined;
};

export type MutationOptions<T, P = T> = {
  onMutate?: () => void;
  onSuccess?: (data: T) => void;
  onError?: (data: P) => void;
};

export type MutationResponse<T> = {
  mutate: (data: T) => void;
  isSaving: boolean;
  errorMessage?: string;
};

export type OptionType<
  TLabel extends string = string,
  TVal extends string = string
> = {
  label: TLabel;
  value: TVal;
  description?: string;
};

export function useApiQuery<Params, Result>(
  key: QueryKey,
  apiFn: (params: Params) => Result | Promise<Result>,
  params: Params,
  queryKeyParams?: Partial<Params>
): QueryResponse<Result> {
  const { setFlashMessage } = useFlashMessage();

  const handleError = useCallback(
    (error: Error) => {
      setFlashMessage({
        severity: 'error',
        message: DEFAULT_ERROR_MESSAGE,
        details: error.message,
      });
    },
    [setFlashMessage]
  );
  const { isLoading, error, data, isFetching } = useQuery<Result, Error>(
    [key, queryKeyParams ?? params],
    () => apiFn(params),
    { retry: false, keepPreviousData: true, onError: handleError }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data,
    isFetching,
  };
}

export function useInfiniteApiQuery<
  Params extends { page?: number; pageSize?: number },
  Result
>(
  key: QueryKey,
  apiFn: (params: Params) => Promise<Page<Result>>,
  params: Params,
  isEnabled = true
): InfiniteQueryResponse<Result> {
  const queryFn = ({ pageParam }: { pageParam?: number }) =>
    apiFn({ ...params, page: pageParam });

  const {
    error,
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<Page<Result>, Error>([key, params], queryFn, {
    enabled: isEnabled,
    getNextPageParam(lastGroup) {
      return (
        lastGroup.meta && nextPageToFetch(lastGroup.meta, params.pageSize || 20)
      );
    },
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data: data?.pages.flatMap((page) => page.data) ?? [],
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    meta: data?.pages[0].meta,
    refetch,
  };
}

/**
 * Passes through the QueryResponse it's given, but makes any returned
 * data and error message "stick" at their last-returned values while
 * updated data is being fetched due to changed parameters.
 *
 * This is useful to prevent a temporary flash to zero-state after the
 * user triggers a change in parameters, but it may also result in stale
 * data remaining on screen if the new query takes a long time or fails
 * to return.
 */
export function useStickyResponse<T>(
  response: QueryResponse<T>
): QueryResponse<T> {
  const { isLoading, errorMessage, data } = response;
  const [cachedData, setCachedData] = useState<T | undefined>(undefined);
  const [cachedError, setCachedError] = useState<string | undefined>(undefined);

  if (!isLoading && cachedData === undefined && data !== undefined) {
    setCachedData(data);
    setCachedError(errorMessage);
  }

  useEffect(() => {
    if (!isLoading) {
      if (data !== undefined) {
        setCachedData(data);
        setCachedError(errorMessage);
      } else if (errorMessage !== undefined) setCachedError(errorMessage);
    }
  }, [isLoading, errorMessage, data]);

  return { isLoading, errorMessage: cachedError, data: cachedData };
}
