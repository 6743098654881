import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';

type UseMergeIntegrationEnabledResult = {
  enabled: boolean;
  enabledIntegrations: string[];
};

export function useMergeIntegrationEnabled(): UseMergeIntegrationEnabledResult {
  const { id: programId } = useProgram();
  const mergeWorkdayEnabled = !!useFeatureFlagsQuery(
    programId,
    'License.Integration.MergeWorkday'
  )?.data?.value;

  const enabledIntegrations: string[] = [];

  if (mergeWorkdayEnabled) {
    enabledIntegrations.push('workday');
  }

  return {
    enabled: mergeWorkdayEnabled,
    enabledIntegrations,
  };
}
