import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { SearchBar } from 'shared/SearchBar';
import { useSearchPrograms } from 'hooks/program';
import { useFlashMessage } from 'contexts/flasher';
import { useDebounce } from 'hooks/useDebounce';
import { LoadingSpinner } from 'shared/LoadingSpinner';

export const ProgramSearch: React.FC<RouteComponentProps> = () => {
  const { setFlashMessage } = useFlashMessage();

  const [query, setQuery] = React.useState('');
  const debouncedQuery = useDebounce(query);

  const onQueryChange = (value: string) => {
    setQuery(value);
  };

  const { isLoading, error, data: results } = useSearchPrograms(
    debouncedQuery.length > 2 ? debouncedQuery : ''
  );

  React.useEffect(() => {
    if (error) {
      setFlashMessage({ message: error.message, severity: 'error' });
    }
  }, [error, setFlashMessage]);

  return (
    <ListPage
      title="Program Search"
      description="Search for programs across all Firstup regions."
      breadcrumbs={[
        { to: '..', label: 'Configure' },
        { label: 'Program Search' },
      ]}
    >
      <SearchBar
        value={query}
        onChange={onQueryChange}
        placeholder="Search for programs"
        width="490px"
      />
      <div style={{ marginTop: '20px' }}>
        {isLoading && query && <LoadingSpinner />}
        {!isLoading && query && results?.programs?.length === 0 && (
          <p>No results found.</p>
        )}
        {results?.programs && results?.programs?.length > 0 && (
          <div>
            <div>
              {results.programs.length >= 30 && (
                <div>
                  <i>
                    Your query returned a lot of results, and some may have been
                    omitted. Use a more specific query to find the most relevant
                    results.
                  </i>
                  <br />
                  <br />
                </div>
              )}
            </div>
            <div>
              {results.programs.map((program) => (
                <div key={program.id} style={{ marginBottom: '20px' }}>
                  <h1>{program.name}</h1>
                  <p>Program ID: {program.id}</p>
                  <p>Brand: {program.brandName}</p>
                  <p>Region: {program.namespace}</p>
                  <a href={program.studioUrl} target="_blank" rel="noreferrer">
                    Visit in Studio
                  </a>
                  &nbsp;|&nbsp;
                  <a href={program.loginUrl} target="_blank" rel="noreferrer">
                    Visit in Experience
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </ListPage>
  );
};
