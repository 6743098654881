import { Box } from 'DesignSystem/Components';
import React from 'react';
import { DefaultLoading } from 'shared/InfiniteList/DefaultLoading';
import { useProgram } from '../../../../../contexts/program';
import { Flex } from '../../../../../DesignSystem/Layout/Flex';
import { Text } from '../../../../../DesignSystem/Typography';
import styles from './workbook-banner.module.css';
import { WorkbookBanner } from './WorkbookBanner';

export type WorkbookListPropsType = {
  workbooks: Tableau.Workbook[];
  categories: Tableau.WorkbookCategory[];
  isLoading: boolean;
};

export const WorkbookList: React.FC<WorkbookListPropsType> = ({
  workbooks,
  categories,
  isLoading,
}) => {
  const programId = useProgram().id;
  const workbookData = React.useMemo(() => {
    return workbooks?.sort((a: Tableau.Workbook, b: Tableau.Workbook) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }, [workbooks]);

  const renderCategory = (category: Tableau.WorkbookCategory) => {
    const categoryWorkbooks = workbookData.filter(({ tags }) =>
      tags.tag.some(({ label }) => label === category.tag)
    );

    return categoryWorkbooks.length === 0
      ? null
      : categoryWorkbooks.map((workbook) => (
          <WorkbookBanner
            key={workbook.id}
            workbook={workbook}
            programId={programId}
          />
        ));
  };

  if (isLoading) {
    return <DefaultLoading />;
  }
  if (!workbooks.length) {
    return <div>No items found.</div>;
  }

  return (
    <Box>
      <Flex start column className={styles.workbookListContainer}>
        <Flex start className={styles.workbookHeader}>
          <div>
            <Text as="h3" className={{ Heading: true, Bold: true }}>
              Insights+
            </Text>
          </div>
          <div>
            <div className={styles.newBadge}>
              <span>New</span>
            </div>
          </div>
        </Flex>
        <Flex start>
          <Text className={{ Body: true, gray60: true }}>
            Advanced analytics provide a deeper, strategic view of communication
            performance beyond standard reports.
          </Text>
        </Flex>
        <Flex start className={styles.workbookList}>
          {categories.map(renderCategory)}
        </Flex>
        <div className={styles.divider} />
      </Flex>
    </Box>
  );
};
