import React, { useCallback } from 'react';
import cx from 'classnames';

import { capitalize } from 'utility/strings';
import { EmptyBanner } from 'shared/BannerListItem/EmptyBanner';
import { MenuItemsType } from 'shared/BannerListItem';
import { Pill } from 'DesignSystem/Components/Pill';
import { Box } from 'DesignSystem/Components';
import { Body, Caption, Text } from 'DesignSystem/Typography';
import { User } from 'models/user';
import { useProgram } from 'contexts/program';
import { JourneyListItem, JourneyState } from 'models/journeys/journey';
import {
  useJourneyStopAction,
  useJourneyCreateDraftAction,
  useJourneyDeleteDraftAction,
  useJourneyAction,
} from 'hooks/journeys/useJourneyActions';
import { useFlashMessage } from 'contexts/flasher';
import { useUnArchiveJourneyMutation } from 'services/api-journey';
import { DateTime } from 'luxon';
import {
  useArchiveJourneyMutation,
  useCancelJourneyProcessing,
} from 'hooks/journeys/journeys';
import { asserts } from 'utility/asserts';
import styles from './journey-list.module.css';
import JourneyDangerActionModal, {
  useJourneyActionModal,
} from '../JourneyModal/JourneyDangerActionModal';
import { cancelPublishingErrorMessage } from '../JourneyCanvasHeader/CancelPublish';

const JourneyStatePill: React.FC<{
  state: JourneyState;
  hasDraft: boolean;
  hasPublishingError: boolean;
}> = ({ state, hasDraft, hasPublishingError }) => {
  switch (state) {
    case 'active': {
      const label = hasDraft ? 'Active with Draft' : 'Active';

      return (
        <Pill
          value={hasPublishingError ? 'Publishing error' : label}
          className={cx(styles.active, {
            [styles.publishingError]: hasPublishingError,
            [styles.activeWithDraft]: hasDraft,
          })}
        />
      );
    }
    case 'initial':
      return (
        <Pill
          value={hasPublishingError ? 'Publishing error' : 'Draft'}
          className={cx(styles.draft, {
            [styles.publishingError]: hasPublishingError,
          })}
        />
      );
    case 'processing':
      return (
        <div className={styles.publishingContainer}>
          <Pill
            value="Publishing"
            className={styles.draft}
            width="fit-content"
          />
          <Text
            className={{
              Caption: true,
            }}
          >
            Processing assets...
          </Text>
        </div>
      );
    case 'paused':
      return (
        <Pill
          value={hasDraft ? 'Paused with Draft' : 'Paused'}
          className={styles.paused}
        />
      );
    default:
      return <Pill value={capitalize(state)} />;
  }
};

export const ListItem: React.FC<{
  journey: Pick<
    JourneyListItem,
    | 'id'
    | 'name'
    | 'createdAt'
    | 'createdBy'
    | 'state'
    | 'draftGraph'
    | 'hasDraft'
    | 'hasLive'
    | 'metrics'
  >;
  authors?: User[];
  isAuthorsLoading?: boolean;
  refreshList: () => void;
}> = ({ journey, authors, isAuthorsLoading, refreshList }) => {
  const { id: programId } = useProgram();

  const [showWarning, setShowWarning, actionModal] = useJourneyActionModal(
    journey
  );

  const { setFlashMessage } = useFlashMessage();
  const onSuccess = useCallback(
    (message: string) => {
      refreshList();
      setFlashMessage({
        message,
        severity: 'info',
      });
    },
    [setFlashMessage, refreshList]
  );

  const onError = useCallback(
    (message: string) => {
      setFlashMessage({
        message,
        severity: 'error',
      });
    },
    [setFlashMessage]
  );

  const { mutateArchive, isLoading: isArchiving } = useArchiveJourneyMutation(
    () => {
      onSuccess(`${journey.name} has been archived.`);
    },
    () => {
      onError(
        `There was an error archiving ${journey.name}. Please try again.`
      );
    }
  );

  const {
    mutateUnArchive,
    isLoading: isUnArchiving,
  } = useUnArchiveJourneyMutation(
    () => {
      onSuccess(`${journey.name} has been unarchived.`);
    },
    () => {
      onError(
        `There was an error unarchiving ${journey.name}. Please try again.`
      );
    }
  );

  const closeModal = () => setShowWarning(null);

  const { isLoading: isStopping } = useJourneyStopAction(
    journey,
    () => {
      closeModal();
      refreshList();
    },
    () => {
      setShowWarning('stop');
    }
  );
  const { isLoading: isPausing } = useJourneyAction('pause', journey, () => {
    closeModal();
    refreshList();
  });
  const { isLoading: isResuming } = useJourneyAction(
    'resume',
    journey,
    () => {
      closeModal();
      refreshList();
    },
    () => {
      setShowWarning('resume-failed');
    }
  );

  const [creatorDisplayName, setCreatorDisplayName] = React.useState<
    string | undefined
  >();

  const { isLoading: isDeletingJourneyDraft } = useJourneyDeleteDraftAction(
    journey,
    () => {
      closeModal();
      refreshList();
    }
  );

  const {
    call: createDraft,
    isLoading: isDrafting,
  } = useJourneyCreateDraftAction(journey, refreshList);

  const { cancelJourneyProcessing, status } = useCancelJourneyProcessing();
  const isCancelling = status === 'loading';

  React.useEffect(() => {
    if (!journey || isAuthorsLoading || creatorDisplayName) return;

    const journeyAuthor = authors?.find(
      (author) => `${author.id}` === `${journey.createdBy}`
    );

    if (!journeyAuthor) setCreatorDisplayName('User');
    else {
      setCreatorDisplayName(
        journeyAuthor.displayName ||
          `${journeyAuthor.firstName} ${journeyAuthor.lastName}`
      );
    }
  }, [authors, creatorDisplayName, isAuthorsLoading, journey]);

  const menuItems: MenuItemsType = [];

  if (journey.state !== 'initial') {
    menuItems.push({
      title: 'View',
      href: `/${programId}/app/journeys/${journey.id}`,
    });
  }

  if (journey.state === 'processing') {
    menuItems.push({
      title: 'Cancel Publish',
      onClick: (e) => {
        e.preventDefault();
        asserts(
          journey.id,
          `Journey [${journey.name}] must have an id to cancel publishing.`
        );
        cancelJourneyProcessing(journey.id, {
          onError(error) {
            setFlashMessage({
              message: cancelPublishingErrorMessage(error),
              severity: 'error',
            });
          },
        });
      },
      disabled: isCancelling,
    });
  }

  if (['active', 'paused'].includes(journey.state) && !journey.hasDraft) {
    menuItems.push({
      title: 'Create draft',
      disabled: isDrafting,
      onClick: (e) => {
        e.preventDefault();
        createDraft();
      },
    });
  } else if (
    (['active', 'paused'].includes(journey.state) && journey.hasDraft) ||
    journey.state === 'initial'
  ) {
    menuItems.push({
      title: 'Edit Draft',
      href: `/${programId}/app/journeys/${journey.id}/edit`,
    });
  }

  if (journey.state === 'initial') {
    menuItems.push({
      title: 'Archive',
      onClick: (e) => {
        e.preventDefault();
        if (journey.id) {
          mutateArchive({ programId, journeyId: journey.id });
        }
      },
      disabled: isArchiving,
    });
  }

  if (journey.state === 'archived') {
    menuItems.push({
      title: 'Unarchive',
      onClick: (e) => {
        e.preventDefault();
        if (journey.id) {
          mutateUnArchive({ programId, journeyId: journey.id });
        }
      },
      disabled: isUnArchiving,
    });
  }

  if (journey.state === 'active') {
    menuItems.push({
      title: 'Stop journey',
      onClick: (e) => {
        e.preventDefault();
        setShowWarning('stop');
      },
      disabled: isStopping,
      danger: true,
    });
    menuItems.push({
      title: 'Pause Journey',
      onClick: (e) => {
        e.preventDefault();
        setShowWarning('pause');
      },
      disabled: isPausing,
    });
  }

  if (journey.state === 'paused') {
    menuItems.push({
      title: 'Resume Journey',
      onClick: (e) => {
        e.preventDefault();
        setShowWarning('resume');
      },
      disabled: isResuming,
    });
    menuItems.push({
      title: 'Stop journey',
      onClick: (e) => {
        e.preventDefault();
        setShowWarning('stop');
      },
      disabled: isStopping,
      danger: true,
    });
  }

  if (['active', 'paused'].includes(journey.state) && journey.hasDraft) {
    menuItems.push({
      title: 'Delete Journey Draft',
      onClick: (e) => {
        e.preventDefault();
        setShowWarning('delete-draft');
      },
      disabled: isDeletingJourneyDraft,
      danger: true,
    });
  }

  const hasPublishingError =
    journey.state === 'initial' &&
    journey.draftGraph?.executionState === 'verifying';

  return (
    <EmptyBanner
      menuItems={menuItems}
      rowId={`journey-${journey.id}`}
      layout="journeys"
    >
      {showWarning !== null && (
        <JourneyDangerActionModal
          title={actionModal.title}
          confirmLabel={actionModal.confirmLabel}
          description={actionModal.description}
          isLoading={actionModal.isLoading}
          onConfirm={actionModal.onConfirm}
          onCancel={actionModal.onCancel}
        />
      )}
      <Box className={cx(styles.title)}>
        <Body semibold>{journey.name || 'Unnamed journey'}</Body>
      </Box>
      <Box className={cx(styles.detail, styles.createdAt)}>
        <Caption semibold>
          {journey.createdAt &&
            `Created ${journey.createdAt.toFormat('MMMM d, yyyy')}`}
        </Caption>
      </Box>
      <Box className={cx(styles.detail, styles.createdBy)}>
        <Caption semibold>
          {creatorDisplayName && `Created by ${creatorDisplayName}`}
        </Caption>
      </Box>
      <Box
        className={cx(styles.detail, styles.statePill)}
        style={{
          display: 'flex',
        }}
      >
        <JourneyStatePill
          state={journey.state}
          hasDraft={journey.hasDraft}
          hasPublishingError={hasPublishingError}
        />
      </Box>
      <Box className={cx(styles.detail, styles.metrics)}>
        {journey?.id && journey.hasLive && journey.metrics && (
          <Metrics metrics={journey.metrics} />
        )}
      </Box>
    </EmptyBanner>
  );
};

const Metrics: React.FC<{
  metrics: {
    currentMembers: number;
    requestedAt: DateTime;
  };
}> = ({ metrics: { currentMembers, requestedAt } }) => {
  return (
    <>
      <Box>
        <Body>Members in Progress: {currentMembers}</Body>
      </Box>
      <Box>
        <Caption>{`As of ${requestedAt.toFormat('MM/d/yy, hh:mm a')}`}</Caption>
      </Box>
    </>
  );
};
