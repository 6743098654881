import React, { useEffect, useState } from 'react';
import { Spinner } from 'App/Program/Configuration/Shared/Spinner';
import { useInfiniteApiQuery } from 'hooks/common';
import { Flex } from 'DesignSystem/Layout/Flex';
import { fetchComments } from 'services/api-content';
import { ViewTrigger } from 'shared/InfiniteList';
import { Modal } from 'shared/Modal';
import { useFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import styles from './comments-modal-v2.module.css';
import { FilterDropdown } from '../FilterDropdown';
import { Comments } from './CommentsV2';

// This is for the consensus migration! The original file is in the CommentsModal.tsx file

type CommentsModalProps = {
  contentId: number;
  onClose: () => void;
  programId: number;
  commentAttachmentsEnabled: boolean;
  nestedRepliesEnabled: boolean;
};

export const CommentsModal: React.FC<CommentsModalProps> = ({
  contentId,
  onClose,
  programId,
  commentAttachmentsEnabled,
  nestedRepliesEnabled,
}) => {
  const [filterLabel, setFilterLabel] = useState('Filter by');
  const [selectedFilters, setSelectedFilters] = useFilterDropdownQueryString(
    'filters'
  );

  const {
    data,
    errorMessage,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteApiQuery('comment_posts', fetchComments, {
    contentId,
    programId,
    filters: selectedFilters,
    pageSize: 10,
  });

  const ref = React.useRef<HTMLDivElement>(null);

  const NoComments = (
    <Flex>
      <p>No comments found.</p>
    </Flex>
  );

  const filtersEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Comments.Filters.Enabled'
  ).data?.value;

  useEffect(() => {
    if (selectedFilters && selectedFilters.length > 0) {
      setFilterLabel('Filtered by');
    } else {
      setFilterLabel('Filter by');
    }
  }, [selectedFilters]);

  const possibleFilters = [
    { value: 'flagged', label: 'Flagged' },
    { value: 'deleted', label: 'Deleted' },
  ];

  const onFilterChange = async (values: string[]) => {
    setSelectedFilters(values);
  };

  const comments = data.map((comment) => comment.attributes).flat();
  const commentsEmpty = comments.length === 0;

  /* eslint-disable no-nested-ternary */
  return (
    <Modal
      title="Comments & Replies"
      showTitle
      showModal
      onClose={onClose}
      modalBodyStyle={{
        width: '700px',
      }}
    >
      <section className={styles.content} ref={ref}>
        {filtersEnabled && (
          <div className={styles.filter}>
            <FilterDropdown
              label={filterLabel}
              options={possibleFilters}
              onChange={(values) => onFilterChange(values)}
              selectedOptions={possibleFilters.filter((opt) =>
                selectedFilters?.includes(opt.value)
              )}
              clearDisabled
              onClear={() => setSelectedFilters([])}
            />
          </div>
        )}
        {errorMessage && <>{errorMessage}</>}

        {isLoading ? (
          <Spinner />
        ) : commentsEmpty ? (
          NoComments
        ) : (
          <>
            {data.map((comment) => {
              return (
                <Comments
                  key={comment.id}
                  comment={comment.attributes}
                  isFiltered={selectedFilters.length > 0}
                  commentAttachmentsEnabled={commentAttachmentsEnabled}
                  nestedRepliesEnabled={nestedRepliesEnabled}
                />
              );
            })}
            {hasNextPage && <ViewTrigger onInView={() => fetchNextPage()} />}
          </>
        )}
      </section>
    </Modal>
  );
};
