import React from 'react';
import { Section } from 'DesignSystem/Form';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { useMergeIntegrations } from '../useMergeIntegrations';
import { WorkdayConfiguration } from './WorkdayConfiguration';
import styles from './styles.module.css';

type SupportedIntegrations = 'workday';

type ConfigureWorkdayIntegrationProps = {
  type: SupportedIntegrations;
};

const INTEGRATION_COMPONENTS: Record<
  SupportedIntegrations,
  {
    title: string;
    component: React.ReactElement;
  }
> = {
  workday: {
    title: 'Workday',
    component: <WorkdayConfiguration />,
  },
};

export const IntegrationConfigurationSection: React.FC<ConfigureWorkdayIntegrationProps> = ({
  type,
}) => {
  const { data, isLoading } = useMergeIntegrations(type);

  const existingIntegration = data?.length ? data[0] : undefined;

  const { title, component } = INTEGRATION_COMPONENTS[type];

  return (
    <Section title={title}>
      <div className={styles.ConfigurationSectionBody}>
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          (existingIntegration?.status === 'active'
            ? `${title} is configured for this program.`
            : component)}
      </div>
    </Section>
  );
};
