import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { MergeIntegration } from '../models/merge-integration';
import { request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export async function fetchIntegrations(
  programId: number,
  type: string
): Promise<MergeIntegration[]> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/integrations/${type}`;
  const response = await request(url);

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error fetching Merge integrations: ${response.status}`);
}

type CreateMergeLinkTokenResponse = {
  id: string;
  linkToken: string;
};

export async function createMergeLinkSession(
  programId: number,
  type: string
): Promise<CreateMergeLinkTokenResponse> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify({ type }),
  });

  if (response.status === 200) {
    return camelcaseKeys(await response.json(), { deep: true });
  }

  throw new Error(`Error creating Merge Link session: ${response.status}`);
}

type CompleteMergeLinkSessionPayload = {
  integrationId: string;
  publicToken: string;
};

export async function completeMergeLinkSession(
  programId: number,
  { integrationId, publicToken }: CompleteMergeLinkSessionPayload
): Promise<void> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/merge_integration/configure/callback`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys({ integrationId, publicToken })),
  });

  if (response.status % 200 > 100) {
    throw new Error(
      `Error completing setup using Merge Link: ${response.status}`
    );
  }
}
