import React from 'react';
import { Box } from '../../../../../../DesignSystem/Components';
import {
  Body,
  Caption,
  color,
  FieldLabel,
} from '../../../../../../DesignSystem/Typography';

export const Setting: React.VFC<{
  label: string;
  description?: string;
  value: string;
}> = ({ label, description, value }) => {
  return (
    <Box margin={[16, 0, 0, 32]}>
      <FieldLabel>{label}</FieldLabel>
      {description && (
        <Box margin={[0, 0, 8, 0]}>
          <Caption color={color.gray80}>{description}</Caption>
        </Box>
      )}
      <Box>
        <Body>{value}</Body>
      </Box>
    </Box>
  );
};
