import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { IntegrationConfigurationSection } from './configure/IntegrationConfigurationSection';
import { useMergeIntegrationEnabled } from './useMergeIntegrationEnabled';

export { useMergeIntegrationEnabled };

export const MergeIntegration: React.FC<RouteComponentProps> = () => {
  const { enabledIntegrations } = useMergeIntegrationEnabled();

  return (
    <FormPage title="Merge">
      {enabledIntegrations.includes('workday') && (
        <IntegrationConfigurationSection type="workday" />
      )}
    </FormPage>
  );
};
