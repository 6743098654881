import React from 'react';
import { NodeProps } from 'reactflow';
import { Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useJourneyState } from 'contexts/journeys/journey';
import { DecisionStep, MetricsMode } from 'models/journeys/journey';
import { useJourneyStepMetrics } from 'hooks/journeys/journey-metrics';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { useJourneyMetricsV2Enabled } from 'hooks/journeyMetricsV2';
import { BaseNode } from './BaseNode';
import { DecisionIcon } from '../icons';
import styles from './node.module.css';
import { Metrics, Metric } from './Metrics';

export const DecisionNode = React.memo((props: NodeProps) => {
  const { id } = props;
  const { journey, metricsMode, currentGraph, getStep } = useJourneyState();
  const step = getStep('decision', id);

  if (!step) {
    return <div />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseNode hideMoveLeft hideMoveRight activateOnClick {...props}>
      <Flex column start className={styles.nodeContainer}>
        <Flex start className={styles.bodyContainer}>
          <Box className={styles.icon}>
            <DecisionIcon />
          </Box>
          <Flex className={styles.messageBody} column start>
            <div>Decision</div>
            <div>{step?.name}</div>
          </Flex>
        </Flex>
        {journey?.id && currentGraph?.id && currentGraph?.isLive && (
          <DecisionMetrics
            journeyId={journey.id}
            graphId={currentGraph.id}
            step={step}
            mode={metricsMode}
          />
        )}
      </Flex>
    </BaseNode>
  );
});

const DecisionMetrics: React.FC<{
  journeyId: number;
  graphId: number;
  step: DecisionStep;
  mode: MetricsMode;
}> = ({ journeyId, graphId, step, mode }) => {
  const { data, isLoading: metricsIsLoading } = useJourneyStepMetrics(
    journeyId,
    graphId,
    'decision',
    step.id
  );
  const notificationCenterEnabled = useNotificationCenterEnabled();
  const {
    enabled: metricsV2Enabled,
    isLoading: metricsV2EnabledLoading,
  } = useJourneyMetricsV2Enabled();

  const isLoading = metricsIsLoading || metricsV2EnabledLoading;
  if (!data) {
    return <Metrics isLoading={isLoading} />;
  }

  if (notificationCenterEnabled || metricsV2Enabled) {
    return (
      <Metrics isLoading={isLoading}>
        <Metric label="Entered" value={data.entered} />
        <Metric label="Exited" value={data.completed} />
      </Metrics>
    );
  }

  if (mode === MetricsMode.members) {
    return (
      <Metrics isLoading={isLoading}>
        <Metric label="Entered" value={data.entered} />
        <Metric label="Current" value={data.current} />
        <Metric label="Exited" value={data.completed} />
      </Metrics>
    );
  }

  return null;
};
