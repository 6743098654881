import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { FetchProps } from 'services/api-content';
import { useDebounce } from 'hooks/useDebounce';
import { ContentFiltersContext } from 'contexts/content/filters';
import {
  ContentFilterBar,
  ContentFilterFetchProps,
} from 'components/content/ContentFilterBar/ContentFilterBar';
import { Alert, AlertType, Box } from 'DesignSystem/Components';
import { MAIcon } from 'shared/MAIcon';
import { BulkProcessingState, PublicationState } from 'models/content';
import { useQueryClient } from 'react-query';
import { Button, Icon } from '@socialchorus/shared-ui-components';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { useAvailableContentPages } from '../availableContentPages';
import { ContentList } from '../ContentList';

export const ContentListPage: React.FC<
  RouteComponentProps & {
    defaultFetchProps: FetchProps;
    pageName: PublicationState;
  }
> = ({ defaultFetchProps, pageName }) => {
  const {
    filters,
    setValue,
    searchParams,
    setBooleanValue,
    updateQueryString,
  } = React.useContext(ContentFiltersContext);

  const {
    permissions: { publishAccess },
  } = usePermissions();

  const { id: programId } = useProgram();
  const navigate = useNavigate();

  const { hasValue, fieldValue } = searchParams;
  const defaultFilterFetchProps = hasValue ? { search: fieldValue } : {};
  const [filterFetchProps, setFilterFetchProps] = useState<
    ContentFilterFetchProps
  >(defaultFilterFetchProps);
  const [bulkProcessingState, setBulkProcessingState] = useState<
    BulkProcessingState
  >(BulkProcessingState.INITIAL);
  // the following variable is used to avoid extra updates
  const [shouldUpdateQueryString, setShouldUpdateQueryString] = useState(false);
  const debouncedFilterFetchProps = useDebounce(filterFetchProps);
  const { search } = debouncedFilterFetchProps;
  React.useEffect(() => {
    setShouldUpdateQueryString(true);
  }, [search]);
  React.useEffect(() => {
    if (shouldUpdateQueryString) {
      setShouldUpdateQueryString(false);
      updateQueryString('search', search || '');
    }
  }, [search, shouldUpdateQueryString, updateQueryString]);
  const availableTabs = useAvailableContentPages();
  const parentRef = React.useRef<HTMLDivElement>(null);
  const fetchProps = { ...defaultFetchProps, ...debouncedFilterFetchProps };

  const queryClient = useQueryClient();
  const refreshList = React.useCallback(() => {
    queryClient.invalidateQueries(['paged-contents']);
    setBulkProcessingState(BulkProcessingState.INITIAL);
  }, [queryClient]);

  const journeysEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Journeys.Enabled'
  ).data?.value;

  const actions = [];
  if (journeysEnabled && publishAccess) {
    actions.push({
      label: 'Create Campaign',
      onClick: () => navigate('#campaigns/create'),
      icon: <Icon>edit</Icon>,
    });
  }

  const AlertBanner = () => {
    return bulkProcessingState !== BulkProcessingState.INITIAL ? (
      <Box padding={[0, 0, 30, 0]}>
        <Alert
          type={AlertType.info}
          title={
            bulkProcessingState === BulkProcessingState.COMPLETED
              ? 'List Update'
              : 'Bulk Changes in Progress'
          }
          message={
            bulkProcessingState === BulkProcessingState.COMPLETED
              ? 'There are changes to this list. Refresh the page to see the latest.'
              : 'There is a bulk action in progress. Some items in this list are unavailable until the bulk action is complete.'
          }
          enableIcon
          icon={<MAIcon name="info" />}
          addon={
            <Button
              variant="text"
              style={{
                color: 'var(--color-brandFull)',
                marginRight: 20,
              }}
              onClick={refreshList}
              label="Refresh"
            />
          }
          compact
        />
      </Box>
    ) : (
      <></>
    );
  };

  return (
    <ListPage
      title="Campaigns"
      actions={actions}
      breadcrumbs={[{ label: 'Campaigns' }]}
      preserveSearch
      tabs={availableTabs.map(({ id, label }) => ({
        to: id,
        label,
      }))}
      filterbar={
        <ContentFilterBar
          fetchProps={filterFetchProps}
          onChange={setFilterFetchProps}
          filters={filters}
          setValue={setValue}
          setBooleanValue={setBooleanValue}
        />
      }
      alert={<AlertBanner />}
    >
      <InfiniteContainer parentRef={parentRef}>
        {(height: number) => (
          <ContentList
            fetchProps={fetchProps}
            height={height}
            parentRef={parentRef}
            setBulkProcessingState={setBulkProcessingState}
            bulkProcessingState={bulkProcessingState}
            pageName={pageName}
          />
        )}
      </InfiniteContainer>
    </ListPage>
  );
};
