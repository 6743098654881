import { FiltersStateType } from 'contexts/content/filters';
import { useContentsInfiniteQuery } from 'hooks/content';
import { useNotificationCenterEnabled } from 'hooks/notification-center';
import { ProgramOrAuthor } from 'hooks/useAuthorsList';
import { useLibraryTemplates } from 'hooks/useLibrary';
import { Content } from 'models/content';
import { ContentPage } from 'models/content-page';
import { DataBlock, FieldData } from 'models/donkey';
import { DeliveryChannel, CHANNEL_NAMES } from 'models/journeys/journey';
import { Template } from 'models/library';
import { isPoll } from 'models/poll';
import { EmailSenderAlias } from 'models/publisher/settings';
import { TemplateSettings } from 'models/template';
import { FetchProps } from 'services/api-content';

type BaseListItem<T extends 'content' | 'template'> = {
  type: T;
  id: number;
  title: string;
  author?: ProgramOrAuthor;
  summary?: string;
  imageUrl?: string;
  createdAt?: string;
  updatedAt?: string;
  emailSenderAlias?: EmailSenderAlias;
  channels: DeliveryChannel[];
  hasPfFile?: boolean;
};

type ContentListItem = BaseListItem<'content'>;

type TemplateListItem = BaseListItem<'template'> & {
  blocks: DataBlock<FieldData>[];
};

export type JourneyListItem = ContentListItem | TemplateListItem;

const parseContents = (
  contents: ContentPage[],
  notificationCenterChannelEnabled: boolean
): ContentListItem[] => {
  const parseDeliveryChannels = (content: Content): DeliveryChannel[] => {
    const channels: DeliveryChannel[] = [];

    if (content.contentCommunication?.channels.includes(CHANNEL_NAMES.EMAIL)) {
      const notification = content.contentCommunication?.notifications[0];
      channels.push({
        name: CHANNEL_NAMES.EMAIL,
        previewText: notification?.previewText ?? '',
        subject: notification?.text ?? '',
        emailSenderAlias: content.emailSenderAlias,
      });
    }

    if (
      notificationCenterChannelEnabled &&
      content.contentCommunication?.channels.includes(CHANNEL_NAMES.PUSH)
    ) {
      channels.push({
        name: CHANNEL_NAMES.PUSH,
        text: content.contentCommunication.notifications[0].pushText ?? '',
      });
    }

    // Map assistant data to notification_center, if enabled
    if (
      notificationCenterChannelEnabled &&
      (content.contentCommunication?.channels.includes('assistant') ||
        content.contentCommunication?.channels.includes(CHANNEL_NAMES.PUSH)) // If we have a push channel, add notification center
    ) {
      channels.push({
        name: CHANNEL_NAMES.NOTIFICATION_CENTER,
        text:
          content.contentCommunication.notifications[0]
            .notificationCenterText ?? '',
        markAsImportant: true,
      });
    }

    return channels.length > 0 ? channels : [];
  };

  return contents
    .flatMap(({ data }) => data)
    .reduce((memo, content) => {
      if (isPoll(content)) return memo;
      return [
        ...memo,
        {
          type: 'content',
          id: Number(content.id),
          title: content.title || 'Untitled',
          summary: content.summary,
          author: content.contentAuthor,
          imageUrl: content.imageUrl,
          createdAt: content.createdAt,
          updatedAt: content.updatedAt,
          channels: parseDeliveryChannels(content),
          hasPfFile: content.hasPfFile,
        },
      ];
    }, [] as ContentListItem[]);
};

const parseTemplates = (templates: Template[]): TemplateListItem[] => {
  const parseDeliveryChannels = (
    settings: TemplateSettings
  ): DeliveryChannel[] => {
    if (settings.deliveryChannels.email) {
      const notification = settings.notifications[0];
      const { emailSenderAlias } = settings;
      return [
        {
          name: 'email',
          previewText: notification?.previewText ?? '',
          subject: notification?.text ?? '',
          emailSenderAlias,
        },
      ];
    }
    return [];
  };

  return templates.map((template) => ({
    type: 'template' as const,
    id: Number(template.id),
    title: template.title,
    summary: template.asset.template.callToAction.title,
    imageUrl: template.asset.template.callToAction.image?.url,
    createdAt: template.created_at,
    channels: parseDeliveryChannels(template.asset.template.settings),
    blocks: template.asset.template.blocks,
  }));
};

export const useJourneyContentList = (
  fetchProps: FetchProps,
  filters: FiltersStateType,
  contentType: JourneyListItem['type']
): {
  isLoading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  items: JourneyListItem[];
} => {
  const notificationCenterChannelEnabled = useNotificationCenterEnabled();
  const contentsQuery = useContentsInfiniteQuery(fetchProps, filters);
  const templatesQuery = useLibraryTemplates({
    filter: {
      type: 'search',
      status: ['published'],
      scope: 'program',
      search:
        fetchProps.search && fetchProps.search.length > 1
          ? fetchProps.search
          : '',
    },
    includeDisabled: false,
    pageSize: 20,
    sortBy: 'title',
  });

  const { isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    contentType === 'content' ? contentsQuery : templatesQuery;

  const items =
    contentType === 'content'
      ? parseContents(contentsQuery.data, notificationCenterChannelEnabled)
      : parseTemplates(templatesQuery.data);

  return {
    items,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};
