import React from 'react';
import * as Type from 'DesignSystem/Typography';
import { FieldInput } from 'DesignSystem/Form';
import { VideoFieldData } from 'models/donkey';
import { Video as VideoObject } from 'models/video';
import { Icon } from 'shared/Icon';
import { MessageBanner } from 'shared/Banner/MessageBanner';
import { EXTERNAL } from '../hooks/useVideoSourceMenu';
import { useVideoPreviewImage } from '../hooks/useVideoPreviewImage';
import { FieldFormProps } from '../../../useFieldForm';
import { MediaUploader } from './MediaUploader';
import { LoadingPlaceholder } from './LoadingPlaceholder';
import styles from './video.module.css';
import { useFetchVideoFromFeed } from '../hooks/useFetchVideoFromFeed';

export const VideoPreviewImage: React.FC<{
  video?: VideoObject;
  refetchVideo: ReturnType<typeof useFetchVideoFromFeed>['refetchVideo'];
  fieldData: VideoFieldData;
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  isVideoLoading: boolean;
  isLoadingVideoFromFeed: boolean;
}> = ({
  video,
  refetchVideo,
  fieldData,
  onChange,
  isVideoLoading,
  isLoadingVideoFromFeed,
}) => {
  const {
    isUploading: isImageLoading,
    image,
    errorMessage,
    fileInput,
    libraryInput,
    externalInput,
    removeCustomPreviewImage,
    menu,
  } = useVideoPreviewImage({ onChange, fieldData, video, refetchVideo });

  const hasVideo = !!fieldData.video_id;
  const showPreviewImage =
    !isImageLoading && hasVideo && !!video?.previewImageUrl;
  const showImagePlaceholder =
    !showPreviewImage &&
    (isLoadingVideoFromFeed || isVideoLoading || isImageLoading);
  const showPreviewWarning =
    showPreviewImage &&
    !!fieldData?.custom_preview_image_url &&
    video?.sourceType === EXTERNAL;
  const previewImageWarningMessage =
    'The preview image from links will override a user-uploaded image.';

  return (
    <>
      <FieldInput
        legend={<Type.FieldLabel>Video Preview Image</Type.FieldLabel>}
      >
        <MediaUploader
          menu={menu}
          placeholderTitle="Add an Image"
          placeholderBody="10 MB max"
          onRemove={removeCustomPreviewImage}
          removeTitle="Remove Video Preview Image"
          disableRemove={!fieldData.custom_preview_image_url}
          disableSourceMenu={!hasVideo || isImageLoading}
          isPreparing={isLoadingVideoFromFeed}
          isUploading={isVideoLoading || isImageLoading}
        >
          {showImagePlaceholder && (
            <div
              className={styles.processingVideoContainer}
              style={{ height: '200px' }}
            >
              {isLoadingVideoFromFeed ? (
                <Icon iconType="SVG" iconName="ImagePlaceholder" size={50} />
              ) : (
                <LoadingPlaceholder prompt="Loading Video Preview Image..." />
              )}
            </div>
          )}
          {showPreviewImage && (
            <img
              style={{
                display: 'block',
                width: '100%',
                height: 'auto',
              }}
              src={video?.previewImageUrl}
              alt={image.altText}
              title={image.altText}
            />
          )}
        </MediaUploader>
        <Type.Body color={Type.color.redFull}>{errorMessage}</Type.Body>
        {showPreviewWarning && (
          <MessageBanner name="warning" actionButton={<></>}>
            {previewImageWarningMessage}
          </MessageBanner>
        )}
        <Type.Body block color={Type.color.gray60}>
          Select or upload a picture that shows what&apos;s in your video. A
          good preview image stands out and draws viewers&apos; attention.
        </Type.Body>
      </FieldInput>

      {fileInput}
      {libraryInput}
      {externalInput}
    </>
  );
};
