import { DateTime } from 'luxon';
import { Duration, DurationOptions } from 'models/duration';
import cx from 'classnames';
import React, { useState } from 'react';
import { ClickDropdown } from 'shared/ClickDropdown';
import { DurationSelector } from 'shared/DurationInput/DurationSelector';
import { capitalizeFirstLetter } from 'utility/strings';
import { selectComponents } from '../Select';
import styles from './DurationSelect.module.css';

function parseValue(value?: string) {
  if (!value) return null;

  const normalizedValue = value.replace(':ignore_year', '');
  const [x, unit] = normalizedValue.split(' ');
  const unitObj = DurationOptions.find((u) => u.value === unit);

  if (!x || !unitObj) return null;

  return {
    label: 'x',
    value: +x,
    unit: unitObj,
  };
}

function durationToQuery(e: Duration) {
  const un = DurationOptions.find((u) => u.value === e.unit?.value);

  return {
    value: `${e.value} ${un?.value}`,
    label: `${e.value} ${un?.value}`,
  };
}

export const DurationSelect: React.FC<{
  onChange: (value?: string) => void;
  value?: string;
  futureDuration?: boolean;
  ignoreYearFlag: boolean;
  ignoreYear: boolean;
  toggleIgnoreYear: () => void;
  newDateOperatorsEnabled?: boolean;
  forceIgnoreYear?: boolean;
  operator?: string;
  isDisabled?: boolean;
}> = ({
  onChange,
  value,
  futureDuration = false,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
  newDateOperatorsEnabled = false,
  operator,
  isDisabled,
  forceIgnoreYear = false,
}) => {
  const parsedValue = parseValue(value);
  const durationValue = parsedValue || {
    label: 'x',
    value: 1,
    unit: DurationOptions[0],
  };
  const [isSelectorOpen, setSelectorState] = useState<boolean>(false);

  const handleDurationChange = (e: Duration) => {
    const duration = durationToQuery(e);
    onChange(duration?.value);
  };

  let startDate = DateTime.now();

  if (!futureDuration) {
    const numberOfUnits = durationValue.value || 1;
    const endDate = DateTime.now();
    switch (durationValue.unit?.value) {
      case 'week':
        startDate = endDate.minus({ days: numberOfUnits * 7 });
        break;
      case 'month':
        startDate = endDate.minus({ months: numberOfUnits });
        break;
      case 'quarter':
        startDate = endDate.minus({ months: numberOfUnits * 3 });
        break;
      case 'year':
        startDate = endDate.minus({ years: numberOfUnits });
        break;
      default:
        startDate = endDate.minus({ days: numberOfUnits });
    }
  }

  const dropdownRenderProp = () => (
    <DurationSelector
      onDurationChange={handleDurationChange}
      value={durationValue}
      startDate={startDate}
      ignoreYearFlag={ignoreYearFlag}
      ignoreYear={ignoreYear}
      toggleIgnoreYear={toggleIgnoreYear}
      operator={operator}
      newDateOperatorsEnabled={newDateOperatorsEnabled}
      forceIgnoreYear={forceIgnoreYear}
    />
  );

  const processDurationValue = (d: Duration) => {
    if (!newDateOperatorsEnabled) return d.unit?.value;
    if (d.value === 0 || d.value > 1) {
      return `${capitalizeFirstLetter(d.unit?.value)}s`;
    }
    return `${capitalizeFirstLetter(d.unit?.value)}`;
  };

  return (
    <div
      className={cx(styles.durationInput, {
        [styles.disabledInput]: isDisabled,
      })}
    >
      <ClickDropdown
        dropdownRenderProp={dropdownRenderProp}
        onOpen={() => setSelectorState(true)}
        onClose={() => setSelectorState(false)}
        isOpen={isSelectorOpen}
        id="durationSelectElement"
        disabled={isDisabled}
        ref={() => {
          if (!parsedValue) onChange(durationToQuery(durationValue).value);
        }}
      >
        <span className={styles.dropdownIndicator}>
          <selectComponents.DropdownIndicator />
        </span>
        <button type="button" className={styles.btnSelect}>
          <span className={styles.label}>
            {`${durationValue.value} ${processDurationValue(durationValue)}`}
          </span>
        </button>
      </ClickDropdown>
    </div>
  );
};
