import { Link } from '@reach/router';
import cx from 'classnames';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as React from 'react';
import { BarChart, FlowChart } from 'shared/icons';
import { Text } from '../../../../../DesignSystem/Typography';
import styles from './workbook-banner.module.css';

export type PropType = {
  workbook: Tableau.Workbook;
  programId: number;
};

export const WorkbookBanner: React.FC<PropType> = ({ workbook, programId }) => {
  const name = workbook.name.toLowerCase();
  let caption = 'View Report';
  if (name.indexOf('journey')) {
    caption =
      'Use this report for an overview of journey performance and detailed insights into each step.';
  } else if (name.indexOf('executive'))
    caption =
      'Use this report to demonstrate ROI and impact with clear communication insights.';

  return (
    <Link
      className={cx(styles.wrapper)}
      to={`/${programId}/app/tableau/workbooks/${workbook.id}/dashboards/${workbook.defaultViewId}`}
      role="button"
      tabIndex={0}
    >
      <Flex start className={cx(styles.workbookCol, styles.workbookHeader)}>
        <div>
          {name.indexOf('executive') !== -1 ? <BarChart /> : <FlowChart />}
        </div>
        <div className={styles.title} title={workbook.name}>
          {workbook.name}
        </div>
      </Flex>
      <Flex start>
        <Text className={{ Body: true }}>{caption}</Text>
      </Flex>
    </Link>
  );
};
