import React from 'react';
import {
  Body,
  PageHeading,
} from '../../../../../../../../DesignSystem/Typography';
import { Box, Tooltip } from '../../../../../../../../DesignSystem/Components';
import { IconNames, MAIcon } from '../../../../../../../../shared/MAIcon';

import styles from './Cards.module.css';

type ChannelDetailList = {
  children: Array<ChannelDetailListRow>;
};

type ChannelDetailListRow = {
  label: string;
  tooltip?: string;
  value: number | string;
  children: Array<ChannelDetailListRow>;
};

export const ChannelDetailCard: React.FC<{
  icon?: IconNames;
  label: string;
  content?: ChannelDetailList;
}> = ({ icon, label, content }) => {
  const buildList = (list: Array<ChannelDetailListRow> | undefined) => {
    if (list === undefined) {
      return null;
    }
    return (
      <ul>
        {list.map((p) => {
          return (
            <li className={styles.channelDetailCard__list_item} key={p.label}>
              <p className={styles.channelDetailCard__list_item_body}>
                <Body>
                  {p.label}{' '}
                  {p.tooltip ? (
                    <Tooltip content={p.tooltip}>
                      <MAIcon
                        name="info"
                        className={
                          styles.channelDetailCard__list_item_tooltip_icon
                        }
                      />
                    </Tooltip>
                  ) : null}
                </Body>
                <Body semibold>{p.value}</Body>
              </p>
              {p.children?.length > 0 ? buildList(p.children) : null}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Box className={styles.channelDetailCard}>
      <Box className={styles.channelDetailCard__header}>
        <Box className={styles.channelDetailCard__header_title}>
          {icon && <MAIcon name={icon} style={{ fontSize: '32px' }} />}
          <PageHeading block={false}>{label}</PageHeading>
        </Box>
      </Box>
      <Box className={styles.channelDetailCard__content}>
        {buildList(content?.children)}
      </Box>
    </Box>
  );
};
