import { RouteComponentProps } from '@reach/router';
import { ListPage } from 'DesignSystem/Layout/Pages';
import * as React from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { WorkbooksContext } from '../contexts/WorkbooksProvider';
import { WorkbookList } from './WorkbookList';

export const WorkbookListPage: React.FC<RouteComponentProps<{
  componentOnly?: boolean;
}>> = ({ componentOnly }) => {
  const { workbooksQuery, categoriesQuery } = useContext(WorkbooksContext);
  const { isLoading: workbooksLoading, workbooks } = workbooksQuery;
  const { isLoading: categoriesLoading, categories } = categoriesQuery;
  // Preload font which is embedded into the tableau reports to avoid FOUC (flash of unloaded content)
  const customFontDomain = `${process.env.REACT_APP_TABLEAU_DOMAIN}/custom-fonts/webfont.html`;

  if (componentOnly)
    return (
      <>
        <WorkbookList
          workbooks={workbooks ?? []}
          categories={categories ?? []}
          isLoading={workbooksLoading || categoriesLoading}
        />
        <Helmet>
          <script type="text/javascript" defer src={`${customFontDomain}`} />
        </Helmet>
      </>
    );
  return (
    <ListPage title="Insights+" breadcrumbs={[{ label: 'Tableau' }]}>
      <WorkbookList
        workbooks={workbooks ?? []}
        categories={categories ?? []}
        isLoading={workbooksLoading || categoriesLoading}
      />
      <Helmet>
        <script type="text/javascript" defer src={`${customFontDomain}`} />
      </Helmet>
    </ListPage>
  );
};
